<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">故障查询</h1>
    </div>
    <a-card :bordered="false" style="margin-top: 20px;">
      <div class="fs-search-box" style="margin-bottom: 20px;">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="所属部门">
              <a-tree-select
                allowClear
                :treeData="deptList"
                v-model="searchParams.departmentId"
                @change="troubleSearch"
                placeholder="请选择巡检部门"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item label="巡检人">
              <a-tree-select
                allowClear
                :treeData="userList"
                v-model="userStr"
                @change="troubleSearch"
                placeholder="请选择巡检人"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item label="巡检结果">
              <a-radio-group @change="selectResult" v-model="searchParams.recordNodeResult">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button value="NORMAL">正常</a-radio-button>
                <a-radio-button value="ABNORMAL">异常</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item>
              <a-radio-group style="margin-right: 5px;" @change="handleChangeMetricsHistoryDate" v-model="dateSelect">
                <a-radio-button value="day">今日</a-radio-button>
                <a-radio-button value="week">本周</a-radio-button>
                <a-radio-button value="month">本月</a-radio-button>
                <!--                <a-radio-button value="year">全年</a-radio-button>-->
              </a-radio-group>
              <a-range-picker
                :value="metricsHistoryTime"
                :showTime="{ format: 'HH:mm' }"
                :allowClear="false"
                :placeholder="['开始时间', '结束时间']"
                format="YYYY-MM-DD HH:mm"
                @ok="handleModalPickerOk"
                @change="(value) => (metricsHistoryTime = value)"
              ></a-range-picker>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="troubleSearch" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
              <a :style="{ marginLeft: '5px', fontSize: '12px' }" @click="toggle">
                {{ expand ? '收起' : '展开' }}
                <a-icon :type="expand ? 'up' : 'down'"/>
              </a>
            </a-form-item>
            <a-row v-if="expand" style="margin-top: 10px;">
              <a-form-item label="巡检点名称">
                <a-input v-model="searchParams.name" placeholder="请输入巡检点名称"></a-input>
              </a-form-item>
            </a-row>
          </a-form>
        </div>
        <div style="margin-top: 20px;">
          <a-row :gutter="12">
            <a-spin :spinning="spinning">
              <a-col :sm="24" :md="8" :lg="8" :xl="8">
                <a-card>
                  <a-statistic
                    title="巡检设备数次"
                    :value="inspectionStatistics.totalTimes"
                    :valueStyle="valueStyle"
                    style="text-align: center;"
                  >
                  </a-statistic>
                </a-card>
              </a-col>
              <a-col :sm="24" :md="8" :lg="8" :xl="8">
                <a-card>
                  <a-statistic
                    title="正常设备数次"
                    :value="inspectionStatistics.normalTimes"
                    :valueStyle="valueStyle"
                    style="text-align: center; color: #1890ff;"
                  >
                  </a-statistic>
                </a-card>
              </a-col>
              <a-col :sm="24" :md="8" :lg="8" :xl="8">
                <a-card>
                  <a-statistic
                    title="异常设备数次"
                    :value="inspectionStatistics.abnormalTimes"
                    :valueStyle="valueStyle"
                    style="text-align: center; color: red;"
                  >
                  </a-statistic>
                </a-card>
              </a-col>
            </a-spin>
          </a-row>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="click"
        @click.stop
        :rowKey="(record) => record.id"
        :scroll="{ x: 1400 }"
        bordered
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="time" slot-scope="text, record">
          {{ record.time }}
        </template>
        <template slot="result" slot-scope="text, record">
          <a-tag color="blue" v-if="record.result === 'NORMAL'">正常</a-tag>
          <a-tag color="red" v-if="record.result === 'ABNORMAL'">异常</a-tag>
        </template>
        <template slot="processStatus" slot-scope="text, record">
          <span v-if="record.processStatus === 'DEFAULT'">-</span>
          <a-tag color="#ff0000" v-if="record.processStatus === 'UN_PROCESS'">待处理</a-tag>
          <a-tag color="#1874CD" v-if="record.processStatus === 'PROCESSED'">已处理</a-tag>
          <a-tag color="#15da8e" v-if="record.processStatus === 'PROCESSING'">处理中</a-tag>
        </template>
        <template slot="node.type" slot-scope="text, record">
          <span v-if="record.node.type === 'device_info'">设备</span>
          <span v-if="record.node.type === 'facility_info'">设施</span>
          <span v-if="record.node.type === 'other'">其他</span>
        </template>
        <template slot="record" slot-scope="record">
          <a @click="clickPath(record)" @click.stop>查看</a>
        </template>
      </a-table>
    </a-card>
    <a-drawer
      :title="recordNodeTitle"
      :visible="infoModal"
      :footer="null"
      @close="closeModal"
      :width="520"
      :mask="false"
      :closable="true"
    >
      <xunjian-record-node-detail ref="recordNodeDetailRef"></xunjian-record-node-detail>
    </a-drawer>
    <a-modal title="巡检详情" :visible="pathDetailsModal" :footer="null" :width="1400"
             centered @cancel="closeModal">
      <xunjian-passed ref="passedRef" :mapHeight="350" :dbDevicesData="dbDevicesData"></xunjian-passed>
    </a-modal>
  </page-layout>
</template>

<script>
  import entityCRUD from '../../common/mixins/entityCRUD'
  import mapDevices from '../../map/composite/mixins/map-devices'

  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { troubleInquireColumns } from './common/common'

  import moment from 'moment'

  import XunjianRecordNodeDetail from '../../map/composite/XunjianRecordNodeDetail'
  import XunjianPassed from '../../map/composite/XunjianPassed'

  export default {
    name: 'myInspection',
    mixins: [entityCRUD, mapDevices],
    components: { PageLayout, XunjianRecordNodeDetail, XunjianPassed },
    data () {
      return {
        valueStyle: {
          color: '',
          textAlign: 'center'
        },
        entityBaseUrl: SERVICE_URLS.xj.xjRecordNode,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          departmentId: undefined,
          name: undefined,
          recordNodeResult: '',
          startTime: '',
          endTime: '',
          userId: undefined
        },
        recordNodeTitle: '',
        initColumns: troubleInquireColumns(),
        infoModal: false,
        metricsHistoryTime: [],
        inspectionStatistics: {},
        deptList: [],
        userList: [],
        expand: false,
        pathDetailsModal: false,
        dateSelect: '',
        spinning: true,
        userStr: undefined
      }
    },
    created () {
      this.getCountTimes()
      this.loadDept()
      this.loadUser()
    },
    methods: {
      handleChangeMetricsHistoryDate (e) {
        this.metricsHistoryTime = [moment().startOf(e.target.value), moment().endOf(e.target.value)]
        this.searchParams.startTime = this.metricsHistoryTime[0].format('YYYY-MM-DD HH:mm:ss')
        this.searchParams.endTime = this.metricsHistoryTime[1].format('YYYY-MM-DD HH:mm:ss')
        this.search()
        this.getCountTimes()
      },
      handleModalPickerOk (value) {
        this.searchParams.startTime = value[0].format('YYYY-MM-DD HH:mm:ss')
        this.searchParams.endTime = value[1].format('YYYY-MM-DD HH:mm:ss')
        this.search()
        this.getCountTimes()
      },
      click (record, index) {
        return {
          on: {
            click: () => {
              this.infoModal = true
              this.$nextTick(() => {
                this.recordNodeTitle = record.node.name + ' 巡检详情'
                this.$refs.recordNodeDetailRef.loadData(record)
              })
            }
          }
        }
      },
      closeModal () {
        this.infoModal = false
        this.pathDetailsModal = false
      },
      selectResult () {
        this.search()
        this.getCountTimes()
      },
      getCountTimes () {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.xj.xjRecordNode.count_times,
          data: this.searchParams,
          noTips: true,
          success: (data) => {
            this.inspectionStatistics = data.body
            this.spinning = false
          }
        })
      },
      loadDept () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
          }
        })
      },
      loadUser () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'org_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.userList = data.body
          }
        })
      },
      troubleSearch () {
        this.searchParams.userId = this.userStr ? this.userStr.split('_')[2] : undefined
        this.search()
        this.getCountTimes()
      },
      toggle () {
        this.expand = !this.expand
      },
      clickPath (record) {
        this.pathDetailsModal = true
        this.$nextTick(() => {
          this.$refs.passedRef.loadData(record.record.id)
        })
      },
      resetForm () {
        this.userStr = undefined
        this.searchParams = {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          departmentId: undefined,
          name: undefined,
          recordNodeResult: '',
          startTime: '',
          endTime: '',
          userId: undefined
        }
        this.dateSelect = ''
        this.metricsHistoryTime = []
        this.search()
        this.getCountTimes()
      }
    }
  }
</script>

<style scoped></style>
