export const troubleInquireColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      scopedSlots: { customRender: '_index' },
      align: 'center'
    },
    {
      title: '巡检点名称',
      dataIndex: 'node.name',
      width: 300,
      align: 'center'
    },
    {
      title: '巡检人',
      dataIndex: 'user.name',
      align: 'center',
      width: 120,
      scopedSlots: { customRender: 'duration' }
    },
    {
      title: '所属部门',
      dataIndex: 'record.department.name',
      align: 'center',
      width: 200,
      scopedSlots: { customRender: 'duration' }
    },
    {
      title: '巡检时间',
      align: 'center',
      width: 200,
      dataIndex: 'dateCreated'
    },
    {
      title: '巡检结果',
      dataIndex: 'result',
      align: 'center',
      width: 100,
      scopedSlots: { customRender: 'result' }
    },
    {
      title: '处理状态',
      dataIndex: 'processStatus',
      align: 'center',
      width: 100,
      scopedSlots: { customRender: 'processStatus' }
    },
    {
      title: '类型',
      align: 'center',
      dataIndex: 'node.type',
      width: 100,
      scopedSlots: { customRender: 'node.type' }
    },
    {
      title: '所在位置',
      align: 'left',
      width: 100,
      dataIndex: 'node.location',
      customRender: (text, record) => {
        const time = `${record.node.area.name  ? record.node.area.name : ''}  ${record.node.location  ? record.node.location : ''}`//es6写法
        return time
      }
    },
    {
      title: '所属路线',
      align: 'center',
      width: 100,
      // customRender: (text, record) => {
      //   const time = `${record.record.startTime !== undefined ? record.record.startTime : ''} ~ ${record.record.endTime !== undefined ? record.record.endTime : ''}`;//es6写法
      //   return time;
      // }
      scopedSlots: { customRender: 'record' }
    }
  ]
}

// 巡检结果
export const inspectionResult = function () {
  return [
    { value: 'ABNORMAL', label: '正常' },
    { value: 'NOT_CHECK', label: '异常' }
  ]
}